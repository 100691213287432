<template>
  <div class="container">
    <h4>Lista pracowników</h4><button @click="addRow" class="add-button">Dodaj pozycje</button>
    <!-- <h3>{{ content }}</h3> -->
    <ul class="responsive-table">
      <li class="table-header">
        <div class="col col-0">Id</div>
        <div class="col col-1">Imię</div>
        <div class="col col-2">Nazwisko</div>
        <div class="col col-1">Data urodzenia</div>
        <div class="col col-1">Miejsce urodzenia</div>
        <div class="col col-1">Stanowisko</div>
        <div class="col col-0">Numer kadrowy</div>
        <div class="col col-2">E-mail</div>
        <div class="col col-0">Operacje</div>
      </li>
      <!-- new Date(cont.birthDate).toString().padStart(2, "0") new Date(cont.birthDate).toDateString() getFormattedDate(cont.birthDate)-->
      <li class="table-row" v-for="(cont, index) in content" :key="cont.id">
        <div class="col col-0" data-label="Id">{{ cont.id }}</div>
        <div class="col col-1" data-label="Imię">{{ cont.names }}</div>
        <div class="col col-2" data-label="Nazwisko">{{ cont.lastName }}</div>
        <div class="col col-1" data-label="Data urodzenia">{{ getFormattedDate(cont.birthDate) }}</div>
        <div class="col col-1" data-label="Miejsce urodzenia">{{ cont.birthPlace }}</div>
        <div class="col col-1" data-label="Stanowisko">{{ cont.position }}</div>
        <div class="col col-0" data-label="Numer kadrowy">{{ cont.number }}</div>
        <div class="col col-2" data-label="E-mail">{{ cont.email }}</div>
        <!-- <div class="col col-1" data-label="Imię" v-if="cont.editing"><input type="text" v-model="cont.name"></div>
        <div class="col col-1" v-if="cont.editing"><input type="text" v-model="cont.lastName"></div> -->
        <div class="col col-0" data-label="">
          <button class="delete-button" @click="deleteRow(index)">Usuń</button>
          <button v-if="!cont.editing" class="edit-button" @click="editRow(cont)">Edytuj</button>
          <button v-if="cont.editing" class="save-button" @click="saveRow(cont)">Zapisz</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import moment from "moment";
export default {
  name: "Admin",
  methods: {
    getFormattedDate(date) {
      if (!date) {
        return null
      }
      return moment(date).format("YYYY-MM-DD")
    },
    editRow(cont) {
      cont.editing = true;
    },
    saveRow(cont) {
      cont.editing = false;
    },
    deleteRow(index) {
      this.content.splice(index, 1);
    },
    addRow() {
      this.content.push({ id: '', names: '', lastName: '', birthDate: '', birthPlace: '', position: '', number: '', email: '', editing: true });
    },
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getEmployee().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style>
.container {
  max-width: 2000px;
  margin-left: 1%;
  margin-right: 1%;
  padding-left: 10px;
  padding-right: 10px;
}

h2 {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
}

h2 small {
  font-size: 0.5em;
}

.responsive-table li {
  border-radius: 3px;
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.responsive-table .table-header {
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.responsive-table .table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

.responsive-table .col-0 {
  flex-basis: 5%;
}
.responsive-table .col-1 {
  flex-basis: 10%;
}

.responsive-table .col-2 {
  flex-basis: 20%;
}

.responsive-table .col-3 {
  flex-basis: 30%;
}

.responsive-table .col-4 {
  flex-basis: 40%;
}

button {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.edit-button {
  background-color: #2196F3;
  color: white;
}

.edit-button:hover {
  background-color: #1976D2;
}

.save-button {
  background-color: #4CAF50;
  color: white;
}

.save-button:hover {
  background-color: #388E3C;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #C62828;
}

.add-button {
  margin-top: 1rem;
  background-color: #4CAF50;
  color: white;
}

.add-button:hover {
  background-color: #3e8e41;
}

@media all and (max-width: 767px) {
  .responsive-table .table-header {
    display: none;
  }

  .responsive-table li {
    display: block;
  }

  .responsive-table .col {
    flex-basis: 100%;
  }

  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }

  .responsive-table .col:before {
    color: #6C7A89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}
</style>
